$notification-column-width: 80px;

.home-projects-container {
  display: flex;
  flex-direction: column;

  &.loading {
    filter: contrast(0.8);
  }

  .accordion-item-container {
    min-height: 188px;
  }

  .accordion-item-header {
    height: 188px;
  }

  .collapse-btn {
    width: 34px;
    height: 34px;
    cursor: pointer;
    margin-right: 24px;
    transform: rotateX(180deg);
    transition: transform 1s ease-in-out;

    &.expanded {
      transform: none;
    }
  }

  .project-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    padding-right: 35px;

    .collapse-btn {
      width: 34px;
      height: 34px;
    }

    > .info-content {
      position: relative;
      width: 100%;
      container-type: inline-size;

      $title-top-margin: -7px;

      > .title {
        margin-top: $title-top-margin;
        font-size: 32px;
        font-weight: 500;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 1;

        > #title {
          min-height: 48px;
          max-height: 48px;
          text-decoration: none;
          color: $primary-color;
        }

        &:hover + .title-tooltip-content {
          &.display-tooltip {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .title-tooltip-content {
        transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0; // For the animation only

        z-index: 2;
        color: white;
        font-size: 32px;
        font-weight: 500;
        position: absolute;
        visibility: hidden;
        overflow: visible;
        left: 0;
        top: $title-top-margin;
        background-color: $primary-color;

        &:hover {
          visibility: visible;
          opacity: 1;
        }
      }

      > .info {
        display: flex;
        justify-content: space-between;
        max-height: 115px;

        .info-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 13px;
          flex: 1;

          font-size: 14px;

          .status-container {
            display: flex;
            gap: 20px;

            .status {
              height: 28px;
            }

            img {
              width: 24px;
              height: 24px;
            }

            .count {
              display: inline-flex;
              align-items: center;
              justify-content: center;

              font-weight: 500;
              background-color: $logo-color;
              color: $white;
              width: 35px;
              margin-left: 6px;

              &.well {
                background-color: $marketing-black;
              }
            }

            .well-tooltip {
              position: relative;

              .well-tooltip-content {
                visibility: hidden;
                opacity: 0; // For the animation only
                transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

                display: flex;
                flex-direction: column;

                min-width: 140px;
                min-height: 50px;
                white-space: nowrap;
                background-color: $tooltip-bg-color;
                padding: 10px 18px;

                color: $white;
                font-size: 16px;
                font-weight: 500;

                /* Position the tooltip */
                position: absolute;
                z-index: 3;
                top: -77%;
                left: 120%;

                &::after {
                  content: '';
                  position: absolute;

                  border-top: 14px solid transparent;
                  border-bottom: 14px solid transparent;
                  border-right: 30px solid $tooltip-bg-color;
                  left: -22px;

                  top: 20px;
                }
              }

              &:hover > .well-tooltip-content {
                visibility: visible;
                opacity: 1; // For the animation only
              }
            }
          }

          > .info {
            display: flex;
            gap: 15px;

            .title {
              font-weight: bold;
              gap: 4px;

              img {
                margin-bottom: 2px;
              }
            }

            .info-container {
              flex-basis: 33%;
              min-width: 0;

              > .info-item {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .title {
                  display: flex;
                }

                span:before {
                  content: '\200b'; // unicode zero width space character, fill empty space if string is empty
                }
              }
            }

            .info-tooltip {
              position: absolute;
              bottom: 23px;

              &.project-tooltip {
                margin-left: 72px;
              }

              &.customer-tooltip {
                margin-left: 115px;
              }

              &.region-tooltip {
                margin-left: 53px;
              }

              .more-info-tooltip {
                top: -65%;
              }
            }
          }
        }

        .progress-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 32px;

          .item {
            height: 85%;
            font-size: 14px;
            margin-bottom: 25px;

            &.health-tooltip {
              position: relative;

              > .health-tooltip-content {
                visibility: hidden;
                opacity: 0; // For the animation only
                transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

                width: 140px;
                height: 130px;
                background-color: $tooltip-bg-color;
                color: #fff;
                text-align: center;
                padding: 5px 0;

                /* Position the tooltip */
                position: absolute;
                z-index: 1;
                top: 0;
                left: 110%;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 11px;

                .health-tooltip-item {
                  display: flex;
                  justify-content: space-between;
                  align-content: space-between;
                  gap: 8px;
                  font-size: 16px;
                  font-weight: 500;

                  span.label {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 14px;
                    width: 80px;

                    &.healthy {
                      background-color: $health-graph-healthy-color;
                    }

                    &.at-risk {
                      background-color: $health-graph-at-risk-color;
                    }

                    &.issues {
                      background-color: $health-graph-issues-color;
                    }
                  }
                }

                &::after {
                  content: '';
                  position: absolute;

                  border-top: 14px solid transparent;
                  border-bottom: 14px solid transparent;
                  border-right: 30px solid $tooltip-bg-color;
                  left: -20px;

                  top: 16px;
                }
              }

              &:hover > .health-tooltip-content {
                visibility: visible;
                opacity: 1; // For the animation only
              }
            }

            > span {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 2px;

              font-weight: 500;
            }

            section.graph {
              width: 97px;
              height: 100%;
              margin: 0 0 2px 0;
              display: grid;

              grid-template-columns: 1fr 2fr 1fr;
              grid-template-rows: 1fr 1fr 1fr;

              .circular-progress {
                box-shadow: inset 0px 0px 0px 14px $graph-green-bg-color;
                color: $graph-green-color;

                grid-column: 1 / 4;
                grid-row: 1 / 4;
                animation-duration: 10s;
              }

              // Move completion text inside of circular progress
              span#circular-progress-text {
                font-size: 1.5em;

                grid-column: 2 / 3;
                grid-row: 2 / 3;
                align-self: center;
                justify-self: center;
                text-align: center;
                padding: 2px 0 0 2px;
              }
            }
          }
        }
      }
    }
  }

  .project-content {
    hr {
      margin: 0 $main-content-margin;
    }

    .accordion-item-container {
      min-height: 50px;
      border: none;
      margin: 0;
      padding-top: 1;
    }

    .accordion-item-header {
      height: 20px;
      margin: 10px 60px 15px 60px;

      img {
        margin-right: 30px;
      }
    }

    .project-content-table {
      margin-bottom: 20px;
    }

    .collapse-btn {
      width: 24px;
      height: 24px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }

    .project-content-container {
      margin: 0 32px;
      display: flex;
      flex-direction: column;

      hr {
        border-top: 2px solid $hr-color;
      }

      table {
        margin-bottom: 5px;

        .notifications-column {
          width: $notification-column-width;
        }

        .so-column {
          min-width: 80px;
        }

        tr {
          height: 49px;
          border-bottom: 1px solid $secondary-color;
        }

        thead {
          font-size: 16px;
          font-weight: 500;

          th.notification {
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $notification-column-width;
          }
        }

        tbody {
          font-size: 14px;

          tr {
            &:last-child {
              border-bottom: none;
            }

            td {
              &.notifications {
                width: $notification-column-width;
                height: 49px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                justify-items: center;

                span {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  min-height: 22px;
                  width: 36px;

                  color: $white;
                  background-color: $logo-color;
                }
              }

              &.so-number {
                font-weight: bold;

                a {
                  color: $marketing-black;
                  text-decoration: none;
                }

                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                  text-decoration-color: $marketing-black;
                  text-decoration-thickness: 2px;
                }
              }

              &.status-indicator {
                span {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  color: $white;
                  font-weight: 500;
                  min-width: 100px;
                  height: 22px;

                  &.control-point {
                    background-color: $status-indicator-new-color;
                  }

                  &.healthy {
                    background-color: $status-indicator-healthy-color;
                  }

                  &.validated {
                    background-color: $status-indicator-validated-color;
                  }

                  &.new {
                    background-color: $status-indicator-new-color;
                  }

                  &.approved {
                    background-color: $status-indicator-approved-color;
                  }

                  &.verified {
                    background-color: $status-indicator-verified-color;
                  }

                  &.confirmed {
                    background-color: $status-indicator-confirmed-color;
                  }

                  &.operations {
                    background-color: $status-indicator-operations-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.home-pagination-container {
  display: flex;
  align-items: center;

  .pagination-text {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 500;
    margin: 0px 20px;
  }

  .pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;

    cursor: pointer;
    background-color: $primary-color;
    color: $white;

    outline: none;
    border: 1px solid transparent;

    &:disabled {
      cursor: not-allowed;
      background-color: $secondary-color;
    }
  }

  $chevron-size: 7px;

  .pagination-btn-left {
    &::before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $chevron-size $chevron-size $chevron-size 0;
      border-color: transparent $white transparent transparent;
      margin-right: $chevron-size;
    }
  }

  .pagination-btn-right {
    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $chevron-size 0 $chevron-size $chevron-size;
      border-color: transparent transparent transparent $white;
      margin-left: $chevron-size;
    }
  }
}
