.home-container {
}

.home-content-container {
  display: flex;

  .projects-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;

    > .projects-banner {
      height: 173px;

      background: url(../../assets/images/projects-banner.webp) no-repeat;
      background-position: 0% 51%;
      background-size: cover;
      margin: 24px 0;
      > .projects-banner-label {
        display: block;
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin: 20px 0 0 28px;
      }
      > .projects-banner-button {
        display: inline-block;
        text-decoration: none;
        font-size: 13px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        padding: 10px 26px 10px 30px;
        background-color: $logo-color;
        border: none;
        margin: 16px 0 0 28px;
        text-transform: uppercase;
      }
    }

    .os-scrollbar-handle {
      width: 9px;
      background-color: #bfc6d1;
    }

    .home-pagination-container {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  }
}

@media (min-width: 1110px) {
  .home-content-container .projects-container {
    padding-bottom: 0px;
  }
}

@media (min-width: $desktop-size) {
  .home-content-container .projects-container {
    padding-bottom: 0px;
    > .projects-banner {
      margin-top: 0;
      margin-left: 75px;
    }
  }
}

@media (min-width: 530px) {
  .home-content-container .projects-container > .projects-banner > .projects-banner-label {
    width: 485px;
    font-size: 32px;
  }
}
