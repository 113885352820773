.contacts-container {
  padding: 10px 0px 19px 18px;
  max-height: 305px;
  h6 {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #262626;
    padding-left: 2px;
  }
  .contacts-list {
    height: 250px;
    margin: 0;
    list-style-type: none;
    padding: 0;
    gap: 19px;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    margin-left: -1px;
    margin-bottom: -1px;

    > .contact-list-item {
      display: flex;
      gap: 8px;
      > .contact-card-user-initials {
        margin-top: 1px;
        @include alphabet-background-color(true);
      }
      > .contact-card-user-initials {
        width: 35px;
        height: 35px;
        font-family: Rubik;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contact-card {
        display: flex;
        flex-direction: column;
        gap: 3px;
        > .contact-name {
          font-size: 14px;
          line-height: normal;
          color: #262626;
        }
        > .contact-phone,
        > .contact-email {
          font-size: 10px;
          line-height: normal;
          color: #262626;
        }
        > .contact-department {
          font-size: 10px;
          font-weight: 500;
          line-height: normal;
          color: #262626;
        }
      }
    }
  }
}
