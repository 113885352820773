.project-container {
  margin: $main-content-margin;
  display: flex;
  padding-bottom: 90px;
  flex-direction: column;
  flex: 1;

  h4.project-stages-title {
    margin: 29px 0 21px 0;
    font-size: 14px;
    font-weight: normal;
  }

  span.back {
    display: block;
    z-index: 1;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    text-align: left;
    color: $marketing-black;
    text-transform: uppercase;
    max-width: 201px;
    min-width: 140px;
    height: 34px;
    padding: 9px 23px 10px 22px;
    backdrop-filter: blur(21px);
    transition: all 0.3s ease-in-out;

    &::before {
      display: block;
      position: absolute;
      content: '';
      background-image: url(../../assets/icons/button_caret_black.svg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 14px;
      height: 14px;
      left: 7px;
      top: 0;
      bottom: 1px;
      margin: auto;
      transform: rotate(270deg);
    }

    @media screen and (max-width: 1320px) {
      padding: 9px 22px 10px 22px;
    }
  }

  > aside.project {
    transition: all 0.3s ease-in-out;
    padding-bottom: 20px;
    margin-right: 20px;

    @media screen and (min-width: $desktop-size) {
      max-width: 288px;
    }

    @media screen and (min-width: 1500px) {
      min-width: 288px;
    }
  }

  > section.project {
    flex: 1;

    .map {
      height: 180px;
    }
  }

  .project-notification-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    background-color: $white;
    border: 1px solid $border-color;
    color: $logo-color;
    cursor: pointer;
    padding: 6px;
    margin: 3px 0;

    div {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    #count {
      display: inline-block;
      color: $white;
      text-align: center;
      background-color: $logo-color;
      width: 24px;
      height: 24px;
    }

    img {
      rotate: 90deg;
      height: 11px;
    }
  }

  @media screen and (min-width: $desktop-size) {
    padding-bottom: 20px;
    margin: 0 $main-content-margin 0 $main-content-margin;
  }
}
@media screen and (min-width: 1024px) {
  .project-container {
    flex-direction: row;
  }
}
