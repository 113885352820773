.aside-collapsable-filter-group-list {
  padding: 14px 15px;

  width: 234px;
  background-color: #f2f2f2;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(16px);
  border: solid 1px #737373;
  background-color: rgba(214, 214, 214, 0.8);

  .aside-container {
    height: 85vh;
  }

  .aside-filter-section-group {
    .filters {
      margin-top: 18px;
      .filter {
        span {
          display: none;
        }
        .filter-container {
          width: 196px;
          border: none;
          box-shadow: none;
          border: solid 1px #b0b0b0;
          height: 30px;
          background-color: white;
          padding-left: 9px;
        }
        select {
          font-family: Rubik;
          font-size: 14px;
          line-height: normal;
          letter-spacing: -0.14px;
          color: #262626;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333333"><path d="M8 10.5l-5-5h10l-5 5z"/></svg>');
          background-repeat: no-repeat;
          background-position-x: 99%;
          background-position-y: 50%;
          padding-right: 18px;
        }
        input::placeholder {
          font-family: Rubik;
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
          line-height: normal;
          letter-spacing: -0.14px;
          text-align: left;
          color: #909090;
        }
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }

    .aside-filter-section-group-header {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: $marketing-black;
      &.mobile-filters-group-header {
        font-family: Univers;
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
      }
    }
    .aside-filter-section-group-content {
      margin-top: 8px;
      .aside-filter-section-container {
        .aside-filter-options-list-container {
          display: grid;
          grid-template-rows: 0fr;
          transition: grid-template-rows 1s ease;
        }
        &.open {
          .aside-filter-options-list-container {
            grid-template-rows: 1fr;
          }

          .aside-filter-section-name.expandable-section-group {
            &::before {
              transform: rotate(180deg);
              bottom: 10px;
            }
            & ~ .aside-filter-options-list-container {
              padding-bottom: 19px;
            }
          }
        }
        ~ .aside-filter-section-container {
          margin-top: -13px;
        }
        .aside-filter-section-name {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.14px;
          text-align: left;
          color: #262626;
          padding-bottom: 8px;
          position: relative;
          margin-bottom: 27px;

          &.selected {
            background-color: $marketing-black;
            color: #fff;
            margin-top: -8px;
            padding-top: 8px;
            padding-left: 5px;
          }

          &::after {
            display: block;
            position: absolute;
            bottom: -1px;
            width: calc(100% + 30px);
            height: 1px;
            background-color: #fff;
            content: '';
            margin: 0 -15px;
          }
          &.expandable-section-group {
            margin-bottom: 7px;
            padding-right: 10px;
            &::before {
              position: absolute;
              content: '';
              background-image: url(../../assets/icons/button_caret_dark.svg);
              background-repeat: no-repeat;
              background-size: 200% 200%;
              background-position: center;
              width: 13px;
              height: 13px;
              right: 0;
              top: 0;
              margin: auto;
              transform: none;
              bottom: 6px;
            }
          }
        }

        .aside-filter-options-list {
          list-style-type: none;
          margin-top: 5px;
          margin-left: 6px;
          overflow: hidden;
          padding-bottom: 19px;
          margin-left: -15px;
          margin-right: -15px;
          padding: 0;

          li {
            padding: 5px 0px 4px 14px;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.13px;
            text-align: left;
            color: #262626;
            cursor: pointer;
            width: 100%;

            &:nth-child(n + 2) {
              margin-top: 6px;
            }
            &.selected {
              background-color: $marketing-black;
              color: #fff;
            }
          }
        }
      }
    }
    + .aside-filter-section-group {
      margin-top: 70px;
    }
  }
}

.aside-filters-mobile-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  animation: backgroundDisappearing 0s ease 0.3s forwards;
  &.open {
    animation: none;
    visibility: visible;
    nav.aside-collapsable-filter-group-list {
      left: 12px;
      opacity: 100;
    }
  }

  nav.aside-collapsable-filter-group-list {
    top: 10px;
    bottom: 11px;
    left: -235px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    .aside-filters-close-button {
      width: 13.1px;
      height: 13.1px;
      position: absolute;
      top: 10.3px;
      right: 0.2px;
      background: url(../../assets/icons/x.svg);
      background-size: contain;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      z-index: 1;
      background-repeat: none;
    }
  }
}

@media (min-width: $desktop-size) {
  .aside-collapsable-filter-group-list {
    position: relative;
    min-width: 166px;
    width: 166px;
    background-color: initial;
    padding: 0;
    min-height: calc(100vh - 134px);
    margin-left: 27px;
    padding-top: 12px;
    align-self: flex-start;
    backdrop-filter: none;
    border: none;
    opacity: 1;
    .aside-filter-section-group .aside-filter-section-group-header {
      font-weight: 400;
      color: $marketing-black;
    }
    &::after {
      background-color: #e5e9ed;
      display: block;
      position: absolute;
      min-height: 100%;
      content: '';
      z-index: -9;
      width: 200px;
      top: 0;
      bottom: 0;
      left: -17px;
    }

    .aside-filter-section-group {
      .filters .filter .filter-container {
        width: initial;
      }
      .aside-filter-section-group-content .aside-filter-section-container {
        .aside-filter-section-name {
          color: $marketing-black;
          &::after {
            width: 100%;
            margin: 0;
            background-color: $marketing-black;
          }
        }
        .aside-filter-options-list {
          margin-left: 6px;
          margin-right: 0px;
          li {
            color: $marketing-black;
            max-width: 158px;
          }
        }
      }
    }
  }
}

@keyframes backgroundDisappearing {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
