.summary-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Background image
  background-size: cover;
  background-position-y: 68%;
  background-size: 100%;
  background-repeat: no-repeat;
  // Background image with a gradient from the middle to the end of the image
  background-image: linear-gradient(
      to right,
      rgba($marketing-black, 0),
      rgba($marketing-black, 0) 40%,
      #1c1c1e 55%,
      #1c1c1e
    ),
    url('../../assets/images/banner_background.jpeg');

  background-color: $primary-color;
  color: $white;

  height: 185px;
  width: 100%;
  margin-bottom: $main-content-margin;
  padding: 0px 174px;
  padding-right: 142px;

  .logo-container {
    font-size: 52px;
    font-weight: 500;
    letter-spacing: 3.4px;

    img {
      padding-bottom: 5px;
      margin-right: 28px;
      height: 87px;
    }
  }

  .user-info-container {
    display: flex;
    // Because of padding on summary-header-container, we need to offset the margin-left to center the user info
    margin-left: -280px;

    // Overrides for MGT
    $avatar-size: 120px;
    --person-avatar-size: 124px; // Can't use a variable here for overriding values in mgt-person
    --person-initials-background-color: $secondary-color;

    .user-image-container {
      border: 3px solid $white;
      background-color: $secondary-color;
      height: calc($avatar-size + 10px);
      width: calc($avatar-size + 10px);
    }

    .welcome-back {
      font-size: 20px;
    }

    .user-name {
      font-size: 30px;
    }

    .notification-count {
      font-size: 16.8px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 65px;

      span:first-child {
        margin-bottom: -10px;
      }

      .notification-container {
        img {
          background-color: $logo-color;
          width: 38px;
          height: 38px;
          padding: 8px;
          margin-right: 8px;
        }
      }
    }
  }

  .summary-info-container {
    display: flex;
    gap: 75px;

    .item-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;

      span {
        text-align: center;
      }

      span.value {
        height: 60px;
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 4px;
      }
    }
  }
}
