.project-details-header {
  .header-info {
    display: flex;
    align-items: start;
    font-size: 28px;
    line-height: 1.7;
    padding-left: 4px;
    height: 100%;
  }

  .header-sub-info {
    display: flex;

    h4,
    p {
      font-size: 10px;
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: normal;
    }

    .initials {
      display: flex;
      justify-content: center;
      font-size: 14px;

      span {
        color: $marketing-black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        background-color: $secondary-color;

        // Map colors to alphabet letters for consistent circle background
        @for $i from 1 through 26 {
          &.alphabet-#{$i} {
            background-color: map-get($alphabet-color-map, nth(map-keys($alphabet-color-map), $i));
          }
        }
      }
    }

    #department {
      font-weight: 500;
    }

    .user-info {
      display: flex;
      gap: 7px;
    }
  }

  .tags {
    height: 100%;
    width: 100%;
  }

  section#sub-header-tags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 4px;
    margin: 4px 0;
    height: 100%;

    h5 {
      font-size: 12px;
      margin: 0;
      padding-top: 4px;
    }

    .job-stage {
      padding: 2px 12px;
    }

    .sub-item {
      min-width: 98px;
    }

    .value-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      gap: 4px;

      .value {
        &.tag {
          margin-top: 2px;
          padding: 4px 12px;
          text-transform: uppercase;
        }
      }

      .business-development {
        background-color: $graph-business-dev-color;
      }

      .technology {
        background-color: $graph-technology-color;
      }

      .manufacturing {
        background-color: $graph-manufacturing-color;
      }

      .operations {
        background-color: $graph-operations-color;
      }

      .post-job-activities {
        background-color: $graph-post-job-act-color;
      }

      .cancelled {
        background-color: $logo-color;
      }
    }
  }
}

@media screen and (min-width: $desktop-size) {
  .project-details-header {
    flex: 0 0 54%;
    .header-info {
      background-color: #f5f5f5;
      width: 100%;
      height: 90px;
      padding-left: 0;

      .sub-item {
        .header-item {
          flex: 0 0 auto;
        }
        &:nth-child(2n) {
          .header-item {
            width: 94px;
          }
        }
        &:nth-child(2n + 1) {
          .header-item {
            width: 65px;
          }
        }
      }
    }
    section#sub-header-tags {
      grid-template-columns: 50% 50%;
      grid-template-rows: 15px 15px 15px;
      align-content: center;
      margin: -3px 0px 11px -2px;

      h5 {
        line-height: normal;
        text-align: right;
        color: $marketing-black;
        white-space: nowrap;
        padding-top: 0;
      }
      .sub-item {
        min-width: 150px;
        display: flex;
        align-items: center;
      }

      .value-container {
        max-height: 35px;
        margin-left: 8px;
        .value {
          font-size: 12px;
          line-height: normal;
        }
      }
    }
  }
}
