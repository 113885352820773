.notifications-container {
  padding-bottom: 90px;
  margin: $main-content-margin;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'aside main';
  grid-gap: 1rem;

  aside.list {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $border-color;
    height: 80vh;
    grid-column: 1/-1;
  }

  section.notification {
    background-color: $white;
    border: 1px solid $border-color;
    height: 80vh;
    grid-column: 1/-1;
    font-size: 16px;

    section.header {
      > .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        height: 60px;

        img {
          height: 24px;
          margin-top: -4px;
          margin-right: 14px;
          margin-left: $notifications-view-content-margin;
        }
      }

      > .details {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        color: $white;
        background-color: $primary-color;

        padding: 25px 0 25px $notifications-view-content-margin;

        .detail {
          display: grid;
          gap: 10px;
          grid-template-columns: 1.4fr 3fr;
          grid-template-rows: 1fr;

          .title {
            font-weight: bold;
            font-size: 14px;
          }

          .info {
            font-size: 14px;
            margin-left: 10px;
          }

          .job-stage {
            background-color: $operations-label-bg-color;
            padding: 2px 12px;
          }
        }
      }
    }

    .main-content {
      margin: $notifications-view-content-margin;
    }

    section.body {
      margin-bottom: $notifications-view-content-margin;

      > .sender {
        display: flex;
        color: $notification-detail-text-color;
        margin-bottom: $notifications-view-content-margin;

        .initials {
          font-size: 26px;
          font-weight: 500;
        }

        .sender-image {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 76px;
          height: 76px;
          background-color: $darker-secondary-color;

          // Map colors to alphabet letters for consistent circle background
          @for $i from 1 through 26 {
            &.alphabet-#{$i} {
              background-color: map-get(
                $alphabet-color-map,
                nth(map-keys($alphabet-color-map), $i)
              );
            }
          }

          &.approved {
            background-color: $approved-img-color;
          }

          &.late {
            background-color: $late-img-color;
          }
        }

        .nonuser-icon {
          width: 76px;
          height: 76px;
        }

        .sender-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 16px;

          .name {
            color: $marketing-black;
            font-weight: 500;
            font-size: 20px;
          }

          .time {
            font-size: 16px;
          }
        }
      }
    }

    section.footer {
      border-top: 1px solid $secondary-color;
      padding-top: $notifications-view-content-margin;

      .attachment {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .icon-container {
          height: 40px;
          width: 40px;
          border: 2px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        img {
          height: 22px;
        }

        > .details {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          .title {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (min-width: 1077px) {
  .notifications-container {
    padding-bottom: 0px;
    aside.list {
      grid-column: initial;
      grid-area: aside;
    }
    section.notification {
      grid-column: initial;
      grid-area: main;
    }
  }
}

@media (min-width: $desktop-size) {
  .notifications-container section.notification section.header .details {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 112px;
  }
}
