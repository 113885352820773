body {
  background-color: #ffffff;
  font-family: Univers !important;
  min-width: 390px; // Verify correct min-width for site
  color: $marketing-black;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  bottom: 5%;
  width: 100%;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 800;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  src: url('./fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 800;
}

@font-face {
  font-family: 'Univers';
  src: url(./fonts/Univers/UniversRegular.ttf) format('truetype');
  font-weight: 400; // normal
}

@font-face {
  font-family: 'Univers Condensed';
  src: url(./fonts/Univers/UniversCnRg.ttf) format('truetype');
  font-weight: 400; // normal condensed
}

@font-face {
  font-family: 'Univers';
  src: url(./fonts/Univers/UniversLight.ttf) format('truetype');
  font-weight: 300; // light
}

@font-face {
  font-family: 'Univers';
  src: url(./fonts/Univers/UniversBold.ttf) format('truetype');
  font-weight: 500; // bold
}

@font-face {
  font-family: 'Univers Condensed';
  src: url(./fonts/Univers/UniversCnBold.ttf) format('truetype');
  font-weight: 500; // bold condensed
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter.ttf) format('truetype');
  font-weight: 500;
}

#okta-sign-in {
  font-family: Univers !important;
}

.main-container {
  margin: 0 $main-content-margin;
}

// OverlayScrollbar overrides
.os-scrollbar .os-scrollbar-handle {
  // --os-handle-bg: $white;
  // --os-handle-bg-hover: $scrollbar-thumb-color;
  --os-handle-bg: rgba(174, 174, 174, 0.44);
  --os-handle-bg-hover: rgba(174, 174, 174, 0.55);
  --os-handle-bg-active: rgba(174, 174, 174, 0.66);
  --os-handle-border-radius: 0;
}

.os-scrollbar-vertical .os-scrollbar-handle {
  margin-right: $scrollbar-right-margin;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hide-on-mobile {
  @media screen and (max-width: $desktop-size) {
    display: none !important;
  }
}

@keyframes popupFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: $popup-opacity;
  }
}

@keyframes popupFadeOut {
  0% {
    visibility: visible;
    opacity: $popup-opacity;
  }
  100% {
    opacity: 0;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid darken($logo-color, 0%);
  border-bottom-color: $background-color;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

/*.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba($marketing-black, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  &.closed {
    opacity: 0;
    left: 100%;
    transition: opacity 0.3s ease-in-out, left ease-in-out 0s 0.3s;
  }
  width: 100vw;
}*/

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: $desktop-size) {
  body {
    background-color: $background-color;
  }
}
