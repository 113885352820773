.project-cards-section-container {
  .project-cards-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
    align-content: flex-start;
    justify-content: center;
  }
  > .loader {
    display: inline-block;
  }
  > .project-cards-section-filter-wrapper {
    display: flex;
    justify-content: space-between;

    align-items: center;
    > .project-cards-section-name {
      font-size: 26px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      text-align: left;
      color: $marketing-black;

      margin-left: 3px;
    }
    > .sort-and-filters-button {
      width: 46.2px;
      height: 27px;
      border: none;
      padding: 0;
      margin: 0;
      background-image: url(../../assets/icons/sort_and_filter.svg);
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
    }
    > .filters {
      display: flex;
      gap: 10px;
      padding: 4px 12px;
      background-color: #efeff4;
      border: 1px #bfc6d1 solid;
      position: relative;
      z-index: 99;
      &::after {
        display: block;
        position: absolute;
        right: 0;
        width: 16px;
        height: 16px;
        content: '';
        background-color: #efeff4;
        top: 0;
        bottom: 0;
        margin: auto -8px auto 0px;
        border-top: 1px #bfc6d1 solid;
        border-right: 1px #bfc6d1 solid;
        transform: rotateZ(45deg);
      }
      > .filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 295px;

        > span {
          margin-top: 8px;
          margin-right: 5px;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 500;
        }

        > select {
          // Custom arrow at the end of the dropdown
          appearance: none; // Hide default arrow
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333333"><path d="M8 10.5l-5-5h10l-5 5z"/></svg>');
          background-repeat: no-repeat;
          background-position-x: 95%;
          background-position-y: 50%;
        }
        > .filter-container {
          border: 1px solid $border-color;
          padding: 0.5rem;
          font-size: 14px;
          color: $marketing-black;
          background-color: $white;
          outline: none;
          width: 100%;
          height: 36px;

          &::placeholder {
            color: $placeholder-text-color;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-size) {
  .project-cards-section-container {
    .project-cards-section {
      gap: 20px 40px;
      padding-right: 24px;
      height: calc(100vh - 359px);
      padding-top: 14px;
    }
  }
  .project-cards-section-container {
    padding-left: 75px;

    > .project-cards-section-filter-wrapper {
      position: relative;
      > .filters {
        visibility: hidden;
        position: absolute;
        right: 98px;
        box-shadow: -1px 2px 4px 0 rgba($marketing-black, 0.25);
        &.opened {
          animation: filtersAppearing 0.3s ease-in forwards;
          visibility: visible;
        }
      }
      > .sort-and-filters-button {
        background-image: url(../../assets/icons/sort_and_filter_grey.svg);
        margin-right: 46px;
      }
    }
  }
}

@keyframes filtersAppearing {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
