$project-header-height: 184px;

.project-item-container {
  background-color: $white;
  min-height: $project-header-height;
  margin-bottom: $main-content-margin;
  border: 1px solid $border-color;

  &.darkened {
    filter: contrast(0.8);
  }
}

.project-item-header {
  height: $project-header-height;
  display: flex;
}
