.project-card-container {
  cursor: pointer;
  padding: 7px 22px 28px 21px;
  width: 100%;
  border: solid 1px #bfc6d1;
  box-shadow: -1px 2px 4px 0 rgba($marketing-black, 0.25);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  > h3 {
    font-weight: 400;
    font-size: 20px;
    align-self: flex-start;
    line-height: normal;
    text-align: center;
    color: $marketing-black;
    flex: 1;
    margin: 0;
    padding-bottom: 1px;
    margin-top: 6px;
  }

  > .project-values-container {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    margin-top: 18px;
    width: 100%;
    height: 88px;

    > .project-overall-progress {
      font-family: 'Univers Condensed';
      font-size: 60px;
      text-align: right;
      color: $marketing-black;
      padding: 0 8px 0 12px;
      min-width: 120px;
    }

    > .project-values {
      list-style-type: none;
      padding: 0 12px 0 8px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: end;
      align-items: baseline;

      > li {
        display: inline-block;
        font-size: 12px;
        line-height: normal;
        padding: 4px 0;
        letter-spacing: -0.12px;
        text-align: left;
        width: 100%;
        color: $marketing-black;

        > .project-value-name {
          font-weight: 500;
          padding-right: 6.4px;
          text-wrap: nowrap;
        }

        > .project-value {
          text-wrap: nowrap;
        }

        &.hover-tooltip-row {
          position: relative;
          &:hover {
            & > .hover-tooltip-content {
              display: block;
            }
          }
          > .hover-tooltip-content {
            display: none;
            position: absolute;

            left: 50%;
            width: 227px;
            margin: auto;
            transform: translateX(-50%) translateY(-100%);
            top: -5px;
            font-size: 12px;
            line-height: normal;
            text-align: left;
            color: #fff;
            background-color: $marketing-black;
            padding: 10px 11px 13px 13px;
            border: solid 1px #909090;
            opacity: 0.95;
            &::after {
              position: absolute;
              display: block;
              content: '';
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 10px solid $marketing-black;
              left: 0;
              right: 0;
              top: 100%;
              margin: auto;
            }
          }
        }
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 6px;
    background-color: $incomplete-color;

    position: relative;

    .progress {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #cc0000;

      // add a set of subclasses with the width of the progress bar for each percentage
      @for $i from 0 through 100 {
        &.width-#{$i} {
          animation: progress-width-#{$i} 1s linear;
          animation-fill-mode: forwards;
        }

        @keyframes progress-width-#{$i} {
          from {
            width: 0;
          }
          to {
            width: #{$i + '%'};
          }
        }
      }
    }
  }
  > .project-card-title-indexes-container {
    width: 100%;
    > .project-card-indexes-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 42px;
      gap: 27px;
      > .project-card-index-container {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 0 100%;

        > .project-index-name {
          font-family: Univers;
          font-size: 14px;
          text-align: left;
          color: $marketing-black;
          padding-top: 7px;

          &.disabled {
            color: #bfc6d1;
          }
        }

        &:hover .project-card-pop-up-container {
          // Hovering on the index container will show the pop-up
          animation: 0.5s popupFadeIn;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .project-card-pop-up-container {
    visibility: hidden;
    // animation: 0.3s popupFadeOut;
    animation-fill-mode: forwards;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -43px;
    left: 185px;
    z-index: 2;

    background-color: rgba($marketing-black, 0.8);
    width: 217px;
    max-width: 305px;
    min-height: 117px;
    border: solid 1px #909090;
    background: rgba(242, 242, 242, 1);
    box-shadow: -1px 2px 4px 0 rgba($marketing-black, 0.25);
    padding: 16px 16px 21px 16px;

    .content {
      display: flex;
      flex-direction: column;
      &.loading {
        justify-content: center;
        align-items: center;
      }
    }

    // Arrow pointing to the left
    &::before {
      content: '';
      opacity: 0.95;
      display: block;
      position: absolute;
      top: 36px;
      left: -39px;
      border: 16px solid transparent;
      border-bottom: 29px solid transparent;
      border-bottom-color: #909090;
      rotate: -90deg;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 36px;
      left: -37px;

      border: 16px solid transparent;
      border-bottom: 29px solid transparent;
      border-bottom-color: rgba(242, 242, 242, 1);
      rotate: -90deg;
    }

    &.left-popup {
      left: -100px;

      // Arrow pointing to the right
      &::before {
        left: 222px;
        border-bottom-color: #909090;
        rotate: 90deg;
      }

      &::after {
        left: 219px;
        border-bottom-color: rgba(242, 242, 242, 1);
        rotate: 90deg;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 500;
    }

    hr {
      margin: 12px 0px;
    }
  }
}
@media (min-width: $desktop-size) {
  .project-card-container {
    width: 100%;
    margin: 0 10px;
    flex-direction: row;
    flex: auto;
    padding: 7px 12px 10px 12px;

    > h3 {
      text-align: left;
      flex: 0 0 100%;
      font-size: 18px;
      align-self: center;
      padding-left: 4px;
      margin-top: 0px;
    }

    > .project-values-container {
      width: 100%;
      justify-content: space-around;
      margin-top: 4px;

      > .project-overall-progress {
        font-size: 36px;
        min-width: 80px;
      }

      > .project-values {
        flex: 1;

        > li {
          padding: 2px 0;
          > .project-value-name {
            padding-right: 7.4px;
          }
        }
      }
    }
    > .project-card-title-indexes-container {
      padding-left: 41px;
      margin-top: -8px;
      flex: 1;
      padding: 21px 1.5vw 0 2.5vw;
      width: 58.5vw;
      > .project-card-indexes-container {
        flex-wrap: nowrap;
        margin-top: 0;
        gap: 0;
        > .project-card-index-container {
          flex: 0 0 25%;
          & > .progress-bar {
            > .progress {
              &.width-100 {
              }
            }
          }
          > .project-index-name {
            text-align: center;
            font-size: 12px;
            padding-top: 5px;
          }
          &:last-child > .progress-bar {
          }

          &:first-child > .progress-bar {
          }
          &:nth-child(n + 2) {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

@media (min-width: 1010px) {
  .project-card-container > .project-values-container {
    min-width: 307px;
    width: auto;
  }
}
