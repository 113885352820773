.sub-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 9px;
    margin-bottom: 0;
  }

  h5 {
    font-size: 14px;
    margin: 0;
    padding-top: 4px;
  }

  .sub-header-container {
    display: flex;
    max-height: 107px;
  }

  .value-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max(166px, 11.5vw);
  }

  .value-container-extended {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.value {
    font-size: 14px;
  }

  section#sub-header-items {
    display: grid;
    grid-template-columns: 1.27fr 1.22fr 1.05fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px 18px;
    margin-top: 0 !important;

    img {
      margin-left: 1px;
      margin-top: -5px;
    }
  }

  .sub-item {
    min-height: 48px;

    .header-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 8px;
      position: relative;

      .more-info-tooltip {
        color: $marketing-black;

        background-color: $tooltip-bg-color-light;
        padding: 10px 18px;
        left: 200%;
        top: -65%;

        &::after {
          border-right: 30px solid $tooltip-bg-color-light;
        }
      }
    }
  }

  section#sub-header-tags {
    margin: 0;

    .job-stage {
      padding: 2px 12px;
    }

    .value-container {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .business-development {
        background-color: $graph-business-dev-color;
      }

      .technology {
        background-color: $graph-technology-color;
      }

      .manufacturing {
        background-color: $graph-manufacturing-color;
      }

      .operations {
        background-color: $graph-operations-color;
      }

      .post-job-activities {
        background-color: $graph-post-job-act-color;
      }
    }
  }
}
