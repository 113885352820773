.sales-order-summary-container {
  $sales-order-container-padding: 28px;

  p {
    margin: 0;
    font-size: 14px;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  .summary-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    background-color: #f5f5f5;
    color: $marketing-black;
    padding: $sales-order-container-padding;

    .supplementary-info-container {
      display: flex;

      gap: 55px;
    }
  }

  .summary-details-container {
    display: flex;
    flex-direction: column;
    height: 434;
    background-color: $white;
    margin: $main-content-margin 0;
    padding: $sales-order-container-padding;
    border: 1px solid $border-color;

    .details-container {
      display: flex;
      height: 100%;
      margin-top: 20px;

      .details-column {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        flex: 1;
        gap: 10px;

        &:not(:last-of-type) {
          border-right: 2px solid $secondary-color;
          padding-right: 26px;
        }

        &:not(:first-of-type) {
          padding-left: 20px;
        }

        &:first-of-type {
          padding-right: 14px;
        }

        @media only screen and (max-width: $desktop-size) {
          &:not(:last-of-type) {
            border: none;
            padding: 0;
          }

          &:not(:first-of-type) {
            padding: 0;
          }

          &:first-of-type {
            padding: 0;
          }
        }

        .details-item {
          height: 71px;

          img {
            margin-left: 5px;
            margin-top: -3px;
          }

          h2 {
            display: flex;

            .more-info-tooltip {
              left: 170%;
              top: -110%;
            }
          }

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        @media only screen and (max-width: $desktop-size) {
          .details-item {
            height: unset;
            margin: 16px 0;
          }
        }
      }

      @media only screen and (max-width: $desktop-size) {
        .details-column {
          flex: unset;
          width: 100%;
          margin: 0;
        }
      }
    }

    @media only screen and (max-width: $desktop-size) {
      .details-container {
        flex-wrap: wrap;
        margin-top: 16px;
      }
    }
  }

  .summary-table-container {
    background-color: $white;
    padding: $sales-order-container-padding;
    border: 1px solid $border-color;

    table {
      font-size: 14px;
      width: 100%;
      margin-top: 25px;

      thead {
        tr {
          height: 46px;
        }

        th {
          &:nth-child(1) {
            width: 25.5%;
          }
          &:nth-child(2) {
            width: 25.4%;
          }
          &:nth-child(3) {
            width: 25.1%;
          }
          &:nth-child(4) {
            width: 24%;
          }
        }
      }

      tbody {
        tr {
          height: 50px;

          // Alternate between gray and white for each row
          &:nth-child(odd) {
            background-color: $selected-item-bg-color;
          }
        }
      }
    }
  }
}
