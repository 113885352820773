$background-color: #ffffff;
$marketing-black: #43494e;
$primary-color: $marketing-black;
$primary-color-dark: #131315;
$primary-color-darker: #0c0c0d;
$secondary-color: #e0e3e6;
$darker-secondary-color: #b0bfca;
$border-color: #b5bac3;
$hr-color: #434448;

$menu-bg-color: #f2f2f2;
$menu-burger-menu-span-color: #4f5e72;

$selected-item-bg-color: #efeff4;
$notification-detail-text-color: #747e8c;
$notification-list-text-color: #747e8c;
$link-color: #126dc0;
$tooltip-bg-color: #322f2f;
$tooltip-bg-color-light: #efeff4;
$placeholder-text-color: #9a9c9d;

$disabled-color: #c9cfd4;

$graph-green-color: #00a31c;
$graph-green-bg-color: rgba($graph-green-color, 0.17);
$graph-business-dev-color: #cc0000;
$graph-business-dev-bg-color: #d9d9d9;
$graph-technology-color: #cc0000;
$graph-technology-bg-color: #d9d9d9;
$graph-manufacturing-color: #cc0000;
$graph-manufacturing-bg-color: #d9d9d9;
$graph-operations-color: #cc0000;
$graph-operations-bg-color: #d9d9d9;
$graph-post-job-act-color: #3ca356;
$graph-post-job-act-bg-color: #344441;

$health-graph-healthy-color: #5fa5ee;
$health-graph-at-risk-color: #f7b500;
$health-graph-issues-color: #c00;

$status-indicator-healthy-color: #5fa5ee;
$status-indicator-operations-color: #009da3;
$status-indicator-new-color: #e6b800;
$status-indicator-approved-color: #3c8e7f;
$status-indicator-verified-color: #9053ae;
$status-indicator-confirmed-color: #0055b3;
$status-indicator-validated-color: #c1407f;

$index-bar-colors: (
  'initiation': #00b050,
  'technology': #00b0f0,
  'manufacturing': #0070c0,
  'operations': #3d0086,
);

$project-status-colors: (
  'initiation': #609ddb,
  'technology': #c1407f,
  'manufacturing': #8f9fb4,
  'operations': #0055b3,
  'complete': #3cab75,
);

$success-color: #c71930;
$complete-color: #3cab75;
$incomplete-color: #d9d9d9;
$subtitle-color: #666666;
$percentage-color: #5d5d5d;
$approved-color: #3c8e80;
$late-color: #cb4141;
$approved-img-color: #e0f2e8;
$late-img-color: #f6dade;

$operations-label-bg-color: #009da3;

$scrollbar-color: #c4c4c4;
$scrollbar-thumb-color: #888888;

$white: #fff;
$logo-color: #c00;
$logo-color-dark: rgb(170, 0, 0);
$logo-color-darker: rgb(150, 0, 0);

$popup-opacity: 0.95;

// Home
$home-banner-total-projects-color: #53a9e5;
$home-banner-active-total-jobs-color: #d74c9f;

// Site styles
$desktop-size: 920px;
$main-content-margin: 20px;
$main-content-margin-vertical: 26px;
$main-border-radius: 8px;
$main-border-radius-soft: 4px;
$main-border-radius-strong: 26px;
$navbar-padding: 0 26px;

$scrollbar-width: 6px;
$scrollbar-right-margin: 8px;
$scrollbar-margin-top-bottom: 10px;

$notification-item-height: 160px;
$notifications-view-content-margin: 30px;

$second-alphabet-color: #dbe7f3;
$first-alphabet-color: #e0f2e8;
$third-alphabet-color: #eddbf3;
$fourth-alphabet-color: #efedda;

// build an alphabet color map
$alphabet-color-map: (
  a: $first-alphabet-color,
  b: $second-alphabet-color,
  c: $third-alphabet-color,
  d: $fourth-alphabet-color,
  e: $first-alphabet-color,
  f: $second-alphabet-color,
  g: $third-alphabet-color,
  h: $fourth-alphabet-color,
  i: $first-alphabet-color,
  j: $second-alphabet-color,
  k: $third-alphabet-color,
  l: $fourth-alphabet-color,
  m: $first-alphabet-color,
  n: $second-alphabet-color,
  o: $third-alphabet-color,
  p: $fourth-alphabet-color,
  q: $first-alphabet-color,
  r: $second-alphabet-color,
  s: $third-alphabet-color,
  t: $fourth-alphabet-color,
  u: $first-alphabet-color,
  v: $second-alphabet-color,
  w: $third-alphabet-color,
  x: $fourth-alphabet-color,
  y: $first-alphabet-color,
  z: $second-alphabet-color,
);

//bootstrap overrides
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-2xl: 0;
$border-radius-pill: 0;
