.notifications-list-container {
  min-height: 467px;
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-around;

    li {
      flex: 1;
      text-align: center;
      padding: 1rem 0;
      border-bottom: 1px solid $border-color;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: $disabled-color;

      span.title {
        display: inline-block;
        vertical-align: middle;
      }

      span.count {
        margin-left: 5px;
        display: inline-block;
        min-width: 30px;
        min-height: 20px;
        background-color: $disabled-color;
        color: $white;
        font-size: 14px;
        padding: 0 3px;
      }

      &.active {
        color: $marketing-black;
        border-bottom: 4px solid $marketing-black;

        span.count {
          background-color: $logo-color;
        }
      }
    }

    li:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  .filters {
    height: 75px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    gap: $main-content-margin * 2;
    padding: 0 30px;
    padding-bottom: 16px;
    margin-top: 16px;

    .filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;

      span {
        font-size: 14px;
        font-weight: 500;
      }

      // Customize the select to have round corners
      select {
        border: 1px solid $border-color;
        padding: 0.5rem;
        font-size: 14px;
        color: $marketing-black;
        cursor: pointer;
        background-color: $white;
        outline: none;
        width: 100%;
        height: 36px;

        // Custom arrow at the end of the dropdown
        appearance: none; // Hide default arrow
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333333"><path d="M8 10.5l-5-5h10l-5 5z"/></svg>');
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%;
      }
    }
  }

  .notifications {
    $hint-width: 5px;
    min-height: 500px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;

    .separator {
      background: $border-color;
      min-height: 1px;
      z-index: 2;
      margin: 0 30px;
    }

    .notification {
      display: flex;
      min-height: $notification-item-height;

      .hint-container {
        width: $hint-width;
        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &.selected {
        background-color: $selected-item-bg-color;
      }
    }

    .notification-content {
      $notification-margin: 30px;
      position: relative;
      user-select: none; // Disable selecting text
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 14px $notification-margin;
      margin-left: -$hint-width;
      font-size: 16px;
      color: $notification-list-text-color;

      .notification-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .sender {
          display: flex;

          .initials {
            font-size: 22px;
            font-weight: 500;
          }

          .sender-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            background-color: $darker-secondary-color;
            border-radius: 50%;
            // Map colors to alphabet letters for consistent circle background
            @include alphabet-background-color(false);

            &.approved {
              background-color: $approved-img-color;
            }

            &.late {
              background-color: $late-img-color;
            }
          }

          .nonuser-icon {
            width: 56px;
            height: 56px;
          }

          .sender-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 16px;

            .name {
              color: $marketing-black;
              font-weight: 500;
            }

            .time {
              font-size: 14px;
            }
          }
        }

        .status-icons {
          display: flex;
          align-items: flex-start;
          margin-top: 8px;
          gap: 10px;

          img {
            height: 16px;
          }

          .status {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 84px;
            margin-right: 12px;

            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.48px;
            font-weight: 500;
            color: $white;

            &.approved {
              background-color: $approved-color;
            }
            &.late {
              background-color: $late-color;
            }
          }
        }

        .icons {
          margin-top: 3px;
          display: flex;
          align-items: flex-start;
          gap: 24px;

          .pin-btn {
            all: initial;
            cursor: pointer;
          }
        }

        #pin {
          cursor: pointer;
        }
      }

      .notification-body-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .notification-body {
          margin-bottom: 4px;
          font-size: 16px;

          // Limit p tag to two lines and add ellipsis if exceeds limit
          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;

            &.unread {
              font-weight: 500;
            }
          }
        }

        .notification-footer {
          span {
            font-size: 14px;
            text-decoration: none;
            color: $link-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-size) {
  .notifications-list-container .notifications .notification {
    max-height: $notification-item-height;
    .hint-container > img {
      width: auto;
    }
  }
}
