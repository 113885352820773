.banner-progress-item-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 5px 19px 19px 12.8px;

  .title-collapse-container {
    display: flex;
    align-items: center;

    .collapse-btn {
      width: 17.5px;
      height: 9px;
      cursor: pointer;
      margin-right: 24px;
      transform: rotateZ(90deg) scale(1.3);
      transition: transform 0.5s ease-in-out;
      margin-top: -9px;

      &.expanded {
        transform: rotateZ(180deg) scale(1.3);
      }
    }

    .title-container {
      padding-top: 4px;
      margin-left: -13px;
      .title {
        font-size: 22px;
        font-weight: 500;
        color: $marketing-black;
        margin-top: 2px;
        margin-left: 1px;
      }

      .subtitle {
        margin-top: 2px;
        font-size: 14px;
        font-weight: normal;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.14px;
        text-align: left;
        color: $marketing-black;
      }
    }
  }

  .progress-container {
    margin-top: 7px;
    width: 100%;
    text-align: right;
    font-size: 18px;
    font-weight: normal;
    color: $percentage-color;
    padding: 0 0px 0 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    .progress-percentage {
      display: block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      text-align: right;
      color: $marketing-black;
      padding-left: 13px;
    }

    .progress-bar {
      width: 100%;
      height: 9px;
      background-color: $incomplete-color;
      position: relative;

      .progress {
        height: 100%;
        background-color: $logo-color;
        position: absolute;
        top: 0;
        left: 0;

        // add a set of subclasses with the width of the progress bar for each percentage
        @for $i from 0 through 100 {
          &.width-#{$i} {
            animation: progress-width-#{$i} 1s linear;
            animation-fill-mode: forwards;
          }

          @keyframes progress-width-#{$i} {
            from {
              width: 0;
            }

            to {
              width: #{$i + '%'};
            }
          }
        }
      }
    }
  }
}
