.footer-container {
  font-family: Univers;
  background-color: $marketing-black;
  height: 150px;
  padding-top: 20px;
  padding-right: 112px;
  padding-left: 112px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .footer-link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .footer-link {
      color: white;
      padding-bottom: 10px;
      padding-right: 3px;
      cursor: pointer;
      font-weight: 100;
      font-size: 16px;
      text-decoration: none;
    }
    @media (max-width: 400px) {
      padding-right: 2%;
      padding-left: 2%;
    }
  }

  @media (max-width: 820px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media (max-width: 1200px) {
    height: 200px;
  }
  @media (max-width: 550px) {
    height: 275px;
  }
  .hal-info {
    color: #9ea6b5;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
  }
}
