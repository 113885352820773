.error-ok-button {
  margin: 5px 30px;
  color: $primary-color;
  background-color: $secondary-color;
  text-align: center;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  border-bottom: 1px solid #1c1c1e;
  cursor: pointer;
  font-size: 10pt;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: $darker-secondary-color;
  }
}
