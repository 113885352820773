.dispatch-item-container {
  $status-icon-height: 40px;
  $status-icon-size: 28px;

  background-color: $primary-color;
  height: 80px;
  max-height: 80px;
  margin-bottom: $main-content-margin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;

  .title-container {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $white;
    font-weight: 500;
    gap: 30px;
    margin-left: 8px;
  }

  img {
    height: 24px;
    width: 24px;
  }

  .status-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $status-icon-height;
    .status-icon {
      flex: 1;
      width: $status-icon-size;
      height: $status-icon-size;
      max-width: $status-icon-size;
      max-height: $status-icon-size;
      position: relative;

      // all but first of type before, put a horizontal line green
      &:not(:first-of-type) {
        margin-left: 58px;
        &::before {
          content: '';
          position: absolute;
          width: 60px;
          height: 6px;
          background-color: $complete-color;
          top: 50%;
          left: 50%;
          margin-left: -43px;
          transform: translate(-50%, -50%);
        }
      }
    }

    .circle {
      width: $status-icon-size;
      height: $status-icon-size;
      max-width: $status-icon-size;
      max-height: $status-icon-size;
      border-radius: 50%;
    }
    .not-started {
      border: 3px solid $complete-color;
    }
    .in-progress {
      background-color: $complete-color;
    }
  }

  @media screen and (max-width: $desktop-size) {
    .status-icon-container {
      .status-icon {
        &:not(:first-of-type) {
          margin-left: 12px;
          &::before {
            width: 13px;
            margin-left: -21px;
          }
        }
      }
    }
  }
}
