.container-with-aside-panel {
  display: flex;

  gap: 14px;

  .content {
    flex: 2;
  }

  aside {
    display: flex;
    flex-direction: column;
    gap: 14px;
    > div {
      display: block;
      flex: 1;
      min-width: 330px;
      background-color: $white;
      border: 1px solid $border-color;
      box-shadow: 0px 4px 4px 0px rgba($marketing-black, 0.15);
    }
  }

  @media screen and (max-width: 1360px) {
    flex-direction: column;

    aside {
      max-width: 100%;
    }
  }
}
