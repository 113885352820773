.search-container {
  margin: $main-content-margin;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 90px;
  flex: 1;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 379px;
  }

  .no-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 379px;
  }

  > div {
    min-height: 326px;
    background-color: $white;
    border: 1px solid $border-color;
  }

  .filters-container {
    padding: 27px 30px;
    display: flex;
    flex-direction: column;

    hr {
      margin-bottom: 20px;
      margin-top: 16px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
        color: $marketing-black;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    .search-buttons {
      display: flex;
      gap: 20px;

      &.mobile {
        margin-top: 16px;
      }

      &.desktop {
        display: none;
      }

      .btn {
        flex: 1 1 auto;
        min-width: 100px;
        transition: 0.15s ease-in all;
        text-transform: uppercase;
        min-height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          filter: brightness(90%);
        }
      }
    }

    .filters {
      display: flex;
      flex-direction: column;
      gap: 15px;

      h3 {
        font-size: 12px;
        font-weight: normal;
        color: $marketing-black;
      }

      .input-filters {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .filter-text {
          display: inline-block;
          width: 100%;
          flex-grow: 1;
          font-size: 12px;

          input {
            padding: 6px 12px;
            height: 27px;
            width: 100%;
            border: solid 1px #c9cfd4;
          }
        }
      }
    }

    .toggleable-filters {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      font-size: 12px;

      .toggle {
        background-color: $marketing-black;
        color: $white;
        text-align: center;
        padding: 3px 0;
        background-color: white;
        border: 1px solid #c9cfd4;
        font-size: 12px;
        font-weight: 300;
        color: #7c8087;
        cursor: pointer;
        transition: 0.15s ease-in all;

        &:hover {
          filter: brightness(90%);
        }
      }

      .creation-date,
      .status {
        flex: 1;
        display: flex;
        flex-direction: column;

        .items {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .toggle {
            min-width: 60px;
          }
        }
      }

      .creation-date {
        .items {
          .toggle {
            &.selected {
              color: $white;
              background-color: $marketing-black;
              border-color: $marketing-black;
            }
          }
        }
      }

      .status {
        .items {
          .toggle:not(.all) {
            min-width: 110px;
          }

          .toggle {
            text-transform: uppercase;
            &.selected {
              color: $white;

              &.initiation {
                background-color: #609ddb;
                border-color: #609ddb;
              }
              &.technology {
                background-color: #c1407f;
                border-color: #c1407f;
              }
              &.manufacturing {
                background-color: #714b8b;
                border-color: #714b8b;
              }
              &.operations {
                background-color: #e6b800;
                border-color: #e6b800;
              }
              &.complete {
                background-color: #3cab75;
                border-color: #3cab75;
              }
              &.canceled {
                background-color: #cb4141;
                border-color: #cb4141;
              }

              &.all {
                background-color: $marketing-black;
                border-color: $marketing-black;
              }
            }
          }
        }
      }
    }

    .btn {
      font-size: 14px;
      background-color: $secondary-color;
      color: #7e8288;

      &.enabled {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

  .results-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 21px 30px;

    hr {
      margin: 0;
    }

    .results-content {
      min-width: 1000px;
      min-height: 337px;
      display: flex;
      flex-direction: column;
    }

    .header {
      font-weight: bold;
      padding-bottom: 11px;

      .results-row {
        height: 15px;
      }
    }

    .content {
      flex: 1;
    }

    .pagination {
      padding: 10px;
      display: flex;
      gap: 8px;
      font-size: 12px;
      line-height: normal;
      color: #808080;

      .pagination-option {
        cursor: pointer;

        &.selected,
        &:hover {
          color: $marketing-black;
        }
      }
    }

    .results-row {
      height: 54px;
      display: flex;
      align-items: center;
      gap: 20px;

      .notification {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.count > span {
          height: 24px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $logo-color;
          color: $white;
          font-size: 10px;
        }
      }

      .stage {
        width: 100px;
      }

      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 6px 0;
        text-transform: uppercase;
        color: $white;

        &.initiation {
          background-color: #609ddb;
          border-color: #609ddb;
        }
        &.technology {
          background-color: #c1407f;
          border-color: #c1407f;
        }
        &.manufacturing {
          background-color: #714b8b;
          border-color: #714b8b;
        }
        &.operations {
          background-color: #e6b800;
          border-color: #e6b800;
        }
        &.complete {
          background-color: #3cab75;
          border-color: #3cab75;
        }
        &.canceled {
          background-color: #cb4141;
          border-color: #cb4141;
        }
      }

      .project-name {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
      }

      .quote-id {
        width: 190px;
      }

      .field-so {
        width: 147px;
      }

      .mfg-so {
        width: 147px;
      }
    }
  }

  @media screen and (min-width: $desktop-size) {
    padding-bottom: 0px;

    .filters-container {
      .filters {
        .input-filters {
          flex-direction: row;
          flex-wrap: wrap;

          .filter-text {
            width: calc(100% * (1 / 4) - 20px);
          }
        }
      }

      .toggleable-filters {
        flex-direction: row;
      }

      .search-buttons {
        &.desktop {
          display: flex;
        }

        &.mobile {
          display: none;
        }
      }
    }
  }
}
