@mixin side-background-image {
  background: linear-gradient(to bottom, rgba(189, 166, 166, 0.4), rgba(81, 68, 68, 0.4)),
    url(../../assets/images/Background.jpg) center no-repeat;
  display: block;
  position: fixed;
  content: '';
  z-index: -9;
  min-height: 100vh;
  width: 225px;
  top: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

@mixin side-background-image-cover {
  display: block;
  position: fixed;
  content: '';
  z-index: -9;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    linear-gradient(26deg, rgba(220, 215, 215, 0.25) -1%, rgba(255, 255, 255, 0.75) 88%);
  border-image-source: radial-gradient(
    circle at 0% 0%,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0) 121%
  );
  backdrop-filter: blur(42px);
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
  min-height: 100vh;
  width: 225px;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 20px 0 rgba($marketing-black, 0.25) inset;
  opacity: 0.8;
}

@mixin side-background-image-cover-v2 {
  @include side-background-image-cover;
  position: absolute;
  min-height: 100%;
  left: -17px;
  border: solid 1px #737373;
  width: 200px;
}

@mixin side-background-image-v2 {
  @include side-background-image;
  position: absolute;
  min-height: 100%;
  left: -17px;
  border: solid 1px #737373;
  width: 200px;
}

@mixin alphabet-background-color($change-font-color) {
  @for $i from 1 through 26 {
    &.alphabet-#{$i} {
      $background-color: map-get($alphabet-color-map, nth(map-keys($alphabet-color-map), $i));
      background-color: $background-color;
      @if $change-font-color {
        color: darken($background-color, 30%);
      }
    }
  }
}
