.navbar {
  background-color: $white;
  display: flex;
  height: 75px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px 0 20px;
  transition: all 0.3s ease;

  .menu {
    display: flex;
    gap: 7px;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: -246px;
    flex-direction: column;
    background-color: $menu-bg-color;
    height: 100vh;
    width: 234px;
    transition: all 0.3s ease;

    backdrop-filter: blur(8px);
    border: solid 1px #737373;
    background-color: rgba(214, 214, 214, 0.8);
    margin: 12px 12px 0px 0px;
    max-height: calc(100vh - 24px);

    &.open {
      right: 0;
    }
    .navigation-menu-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      .navigation-menu-title {
        padding-left: 14px;
        padding-top: 5px;
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
      }

      .close-menu {
        cursor: pointer;
        align-self: flex-end;
        margin: 13px 12px 9px 0px;
      }
    }
  }

  .profile-menu {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 36px;
    --person-avatar-size: 30px;
    --person-initials-background-color: #b7cde3;
    z-index: 999;
    padding-bottom: 3px;

    .profile {
      cursor: pointer;
      height: 30px;
      width: 30px;
      background-color: #b7cde3;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 11px;
    }

    .logout-menu {
      background-color: darken($white, 5%);
      right: 125px;
      display: none;
      cursor: pointer;
      position: absolute;
      border: 1px solid $secondary-color;
      padding: 8px 16px;

      &:hover {
        background-color: $secondary-color;
        color: $marketing-black;
      }

      &.open {
        display: block;
      }

      &:active {
        background-color: darken($secondary-color, 10%);
      }
    }
  }

  .burger-menu-toggle {
    cursor: pointer;

    span {
      background-color: $marketing-black;
      height: 1px;
      width: 15px;
      display: block;
      flex-grow: 0;
      margin: 0 0 5px;
      background-color: $menu-burger-menu-span-color;
    }
  }

  .navbar-brand {
    color: $logo-color !important;
    font-weight: 500;
    font-size: 20px;
    margin-left: 17px;
    margin-right: 0;
    margin-bottom: 4px;

    // Logo/Title image sizing
    img {
      height: 30px;
      width: 200px;
    }
  }
  > a:first-child {
    margin-top: 2px;
  }
  .navbar-button {
    padding: 0px 16px 0 14px;
    text-decoration: none;
    font-size: 18px;
    line-height: normal;
    letter-spacing: -0.18px;
    text-align: left;
    color: #262626;
    transition: all 0.3s ease;
    font-family: Rubik;

    &:hover {
      background-color: $secondary-color;
    }

    &.active {
      background-color: $marketing-black;
      color: $white;
    }
  }

  .ai-helper {
    @extend .prevent-select;
    cursor: pointer;
    position: fixed;
    display: block;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    width: 251px;
    height: 35px;
    flex-grow: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(100% - 69px);
    z-index: -1;

    border: solid 1px #d4d4d4;
    background-color: #fff;

    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #858893;
    padding: 10px 25.5px 10px 54.5px;
    &:hover {
      background-color: #ece3e3;
    }

    &::before {
      display: block;
      content: '';
      background: url(../../assets/images/haltrax-small-logo.png);
      background-color: white;
      background-size: contain;
      width: 32px;
      height: 32px;
      position: absolute;
      left: 2px;
      top: 1px;
    }
  }
}

@media (min-width: $desktop-size) {
  .navbar {
    background-color: unset;
    height: 99px;
    padding: $navbar-padding;

    .navbar-brand {
      width: 250px;
      margin-right: 32px;
      margin-left: 0;

      img {
        height: 48px;
        width: 202px;
      }
    }
    .navbar-button {
      color: $marketing-black;
      padding: 5px 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: inherit;
      &.active {
        font-weight: 500;
      }
    }

    .menu {
      gap: 6px;
      transition: none;
      right: unset;
      display: flex;
      flex: 1; // Since this is the only element getting the size explicitly set, it will be the only one to change size and take the remaining space
      flex-direction: row;
      position: relative;
      background-color: unset;
      height: unset;
      z-index: 99;
      backdrop-filter: none;
      border: none;
      background-color: transparent;
      margin: 0;
      max-height: initial;
      .close-menu {
        display: none;
      }
      .navigation-menu-title-container .navigation-menu-title {
        display: none;
      }
    }

    .profile-menu {
      margin-left: 20px; // To make space for the logout menu
      padding-bottom: 0px;
      .profile {
        height: 48px;
        width: 48px;
        font-size: inherit;
      }
      .logout-menu {
        right: 60px;
      }
    }

    .burger-menu-toggle {
      display: none;
    }

    .ai-helper {
      position: relative;
      bottom: initial;
      display: block;
      width: initial;
      padding: 0px;
      width: 33px;
      height: 33px;
      border: none;
      &::before {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

@media (min-width: 1125px) {
  .navbar .ai-helper {
    width: 251px;
    bottom: initial;
    position: relative;

    padding: 10px 25.5px 10px 54.5px;
    &::before {
      left: 2px;
      top: 1px;
      margin: 0;
    }
  }
}
