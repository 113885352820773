.headers-with-project-filters {
  display: flex;

  gap: $main-content-margin;

  margin-bottom: 10px;

  h1 {
    font-size: 40px;
    letter-spacing: -0.04em;
  }

  .content {
    flex: 2;
    display: flex;
    justify-content: space-between;
    margin-right: 2px;

    .filters {
      display: flex;
      gap: 10px;
    }

    .filter-container {
      border: 1px solid $border-color;
      padding: 0.5rem;
      font-size: 14px;
      color: $marketing-black;
      background-color: $white;
      outline: none;
      width: 100%;
      height: 36px;

      &::placeholder {
        color: $placeholder-text-color;
      }
    }

    .filter {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 295px;

      span {
        margin-top: 8px;
        margin-right: 5px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
      }

      select {
        // Custom arrow at the end of the dropdown
        appearance: none; // Hide default arrow
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333333"><path d="M8 10.5l-5-5h10l-5 5z"/></svg>');
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%;
      }
    }
  }

  aside {
    flex: 1;
    max-width: 621px;
  }
}
