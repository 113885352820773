$accordion-header-height: 103px;

.accordion-item-container {
  background-color: $white;
  min-height: $accordion-header-height;
  margin-bottom: $main-content-margin;
  border: 1px solid $border-color;

  &.darkened {
    filter: contrast(0.8);
  }
}

.accordion-item-header {
  height: $accordion-header-height;
  display: flex;
}

.accordion-item-content {
  overflow: hidden;
  max-height: 0;
  max-width: 89vw;

  // closing animation
  transition: max-height 1.5s cubic-bezier(0.42, 0, 0, 1.01);

  &.expanded {
    max-height: 100vh;
    // opening animation
    transition: max-height 2s ease-in;
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 60px 20px 60px;
    height: 100%;
    font-size: 14px;
    color: $marketing-black;
  }
}

@media screen and (min-width: $desktop-size) {
  .accordion-item-content {
    max-width: 100%;
  }
}
