.home-tabs-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 14px;
}

.home-tabs-tab {
  font-weight: 500;
  color: $marketing-black;

  height: 31px;
  width: 130px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &.active {
    background-color: $marketing-black;
    color: $white;

    &:before {
      // Bottom triangle
      content: '';
      border: 5px solid transparent;

      position: absolute;
      border-top-color: $marketing-black;
      border-bottom: 0;

      bottom: -5px;
      left: 50%;
      margin-left: -5px;
    }
  }
}
