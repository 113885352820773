$status-icon-height: 40px;
$status-icon-size: 20px;

.project-stage-container-scrollbar {
  padding-bottom: 15px;
}

.project-stage-container {
  margin: 0px 30px 0px 30px;
  font-size: 14px;
  max-height: 562.5px;
  min-width: 750px;

  .bold {
    font-weight: bold;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  div {
    display: flex;
  }

  .size-5 {
    flex-basis: 5%;
  }

  .size-15 {
    flex-basis: 15%;
  }

  .size-10 {
    flex-basis: 10%;
  }

  .size-20 {
    flex-basis: 20%;
  }

  .size-25 {
    flex-basis: 25%;
  }

  .project-stage-titles {
    font-weight: bold;
    font-size: 15px;

    .project-stage-title {
      padding-right: 10px;
    }
  }

  .project-stage-values {
    flex-direction: column;

    .project-stage-value {
      &.center {
        justify-content: center;
      }
    }
  }

  .status-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .status-icon {
      flex: 1;
      width: $status-icon-size;
      height: $status-icon-size;
      max-width: $status-icon-size;
      max-height: $status-icon-size;
    }

    .circle {
      width: $status-icon-size;
      height: $status-icon-size;
      max-width: $status-icon-size;
      max-height: $status-icon-size;
      margin-bottom: 1px;
      border-radius: 50%;
    }

    .not-started {
      border: 2px solid $darker-secondary-color;
    }

    .in-progress {
      background-color: $complete-color;
    }
  }
}
