.job-container {
  @extend .project-container;
  flex: 1;
  > aside.job {
    @extend aside, .project;
  }

  > section.job {
    @extend section, .project;
  }

  @media only screen and (max-width: $desktop-size) {
    > section.job {
      flex: unset;
    }
  }
}
