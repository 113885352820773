$chart-thickness: 6.5px;

.chart-header {
  color: $marketing-black;
  background-color: white;
  height: 248px;
  padding: 16px;

  display: flex;
  flex-direction: column;

  .title {
    max-width: 80vw;
    font-size: 22px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 21px;
    margin-bottom: -4px;
  }

  .chart-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  section.graphs {
    display: flex;
    justify-content: space-between;
  }

  .circular-progress-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;

    div {
      // Center graph with text underneath
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .chart-title {
        font-size: 12px;
        font-weight: 500;
      }

      section {
        width: 48px;
        margin: 0;
        display: grid;

        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        // First color for graph
        .circular-progress {
          // Background for circular progress
          box-shadow: inset 0px 0px 0px $chart-thickness $graph-business-dev-bg-color;
          color: $graph-business-dev-color;
          border-radius: 50%;
          grid-column: 1 / 4;
          grid-row: 1 / 4;
          animation-duration: 10s;
        }

        // Move completion text inside of circular progress
        span#circular-progress-text {
          font-size: 9.6px;

          grid-column: 2 / 3;
          grid-row: 2 / 3;
          align-self: center;
          justify-self: center;
          text-align: center;
          padding-top: 2px;
        }
      }

      // Subsequent colors per child #
      &:nth-child(2) .circular-progress {
        color: $graph-technology-color;
        box-shadow: inset 0px 0px 0px $chart-thickness $graph-technology-bg-color;
      }

      &:nth-child(3) .circular-progress {
        color: $graph-manufacturing-color;
        box-shadow: inset 0px 0px 0px $chart-thickness $graph-manufacturing-bg-color;
      }

      &:nth-child(4) .circular-progress {
        color: $graph-operations-color;
        box-shadow: inset 0px 0px 0px $chart-thickness $graph-operations-bg-color;
      }

      &:nth-child(5) .circular-progress {
        color: $graph-post-job-act-color;
        box-shadow: inset 0px 0px 0px $chart-thickness $graph-post-job-act-bg-color;
      }

      span {
        font-size: 0.875em;
      }
    }
  }
}

@media screen and (min-width: $desktop-size) {
  .chart-header {
    height: 182px;
    padding: 17px 21px 21px 21px;
    border: solid 1px #bfbfbf;
    background-color: #fff;
    color: $marketing-black;

    .title {
      font-size: 26px;
      max-width: 48vw;
      padding-bottom: 30px;
      margin-bottom: 0;
      font-size: 26px;
      line-height: normal;
      letter-spacing: -0.26px;
      margin-left: -3px;
    }

    .chart-container {
      flex-direction: row;
      margin-top: 23px;
    }

    section.graphs {
      justify-content: center;
    }

    .circular-progress-container {
      div {
        gap: 10px;
        width: 83px;
        .chart-title {
          line-height: normal;
          letter-spacing: -0.08px;
          text-align: center;
        }
        section {
          width: 71px;

          span#circular-progress-text {
            font-size: 12px;
          }
        }
      }
    }
  }
}
