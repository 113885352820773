$landing-title-gap: 60px;

.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $landing-title-gap * 2;
  height: 100vh;
  color: $white;

  background-image: url('../../assets/images/banner_background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

section.login-container {
  width: 643px;
  height: 520px;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 253px;
    background-color: transparent;
    background-image: url('../../assets/images/header.png');
    background-size: cover;
    margin-top: -$landing-title-gap; // To center the login box from the gap

    h1 {
      font-size: 24px;
      font-weight: normal;
      margin-top: -22px;
      margin-bottom: 42px;
    }
    .title-logo {
      font-size: 60px;
      font-weight: bold;
      font-family: 'Inter';
      text-align: center;
    }
  }

  .login-cta-container {
    width: 643px;
    height: 267px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    box-shadow: 0 8px 30px 0 rgba($marketing-black, 0.4);
    background-color: rgba(87, 93, 97, 0.5);
  }

  .landing-log-in-btn {
    width: 335px;
    background-color: $primary-color;
    border-color: $primary-color;

    text-transform: uppercase;

    &:hover {
      background-color: $primary-color-dark;
      border-color: $primary-color-dark;
    }

    &:active {
      background-color: $primary-color-darker !important;
      border-color: $primary-color-darker !important;
    }
  }
}
