.more-info-tooltip-container {
  position: relative;
  display: block;

  > .more-info-tooltip {
    visibility: hidden;
    opacity: 0; // For the animation only
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

    display: flex;
    flex-direction: column;

    background-color: $tooltip-bg-color;
    padding: 10px 18px;

    color: $white;
    font-size: 16px;
    font-weight: 500;

    /* Position the tooltip */
    position: absolute;
    z-index: 3;
    top: -50%;
    left: 215%;

    &::after {
      content: '';
      position: absolute;

      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 30px solid $tooltip-bg-color;
      left: -22px;

      top: 12px;
    }
  }

  &:hover > .more-info-tooltip {
    visibility: visible;
    opacity: 1; // For the animation only
  }
}
